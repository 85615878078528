@import 'variables'

@font-face 
  font-family: 'LufgaMedium'
  src: url('../assets/fonts/LufgaChargie-Medium.ttf') format('truetype')
  font-weight: normal


@font-face 
  font-family: 'LufgaRegular'
  src: url('../assets/fonts/LufgaChargie-Regular.ttf') format('truetype')
  font-weight: normal


body 
  font-family: 'LufgaRegular', sans-serif
  margin: 0
  background: $background

.flex-row
  display: flex
  flex-direction: row
  justify-content: space-evenly

.flex-col
  display: flex
  flex-flow: column
  text-align: center

.bottom-border
  border-bottom: solid
  border-color: #E8E8E8
  padding: 10px 0

.capitalized
  text-transform: uppercase

.padding-10y
  padding: 10px 0

.right-border
  border-right: solid
  border-color: #E8E8E8
  padding: 0 10px

.white-btn
  background-color: white
  --cui-btn-bg: white

.black-btn
  background-color: black
  --cui-btn-bg: black

.blue-btn
  background-color: #144BFF
  --cui-btn-bg: #144BFF

.white-font
  color: white

.bolder-font
  font-family: 'LufgaMedium', sans-serif

.small-font
  font-size: 75%

.big-font
  font-size: 1.2rem

.gray-font
  color: gray

.centered-logo
  width: 79px
  height: 79px
  box-shadow: 3px 4px 9px rgba(0, 0, 0, 0.25)
  border-radius: 5px

.header.text-bg-light
  background-color: transparent !important
  border: 0